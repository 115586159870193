import type { ApolloClient } from '@apollo/client';
import { useMutation } from '@vue/apollo-composable';

import {
  CREATE_SAVED_SEARCH,
  DELETE_SAVED_SEARCH_BY_ID,
  GET_SAVED_SEARCHES_BY_USER,
  UPDATE_SAVED_SEARCH_BY_ID
} from '@/utils/queries';
import {
  areaOptions as areaOptionsRaw,
  buyPrices,
  buyTypeOptionsAlt as buyTypeOptionsAltRaw,
  buyTypeOptions as buyTypeOptionsRaw,
  capitalizeWords,
  condoCostOptions as condoCostOptionsRaw,
  createSearchParams as createSearchParamsRaw,
  hopaOptions as hopaOptionsRaw,
  paramToValue,
  projectTypeOptions as projectTypeOptionsRaw,
  propertySubTypeOptions as propertySubTypeOptionsRaw,
  propertySubTypesOptions as propertySubTypesOptionsRaw,
  propertyTypesOptions as propertyTypesOptionsRaw,
  rentPrices,
  sortByOptions as sortByOptionsRaw,
  sortProjectsByOptions as sortProjectsByOptionsRaw,
  valuesMap,
  valueToParam,
  viewOptions as viewOptionsRaw,
  yearOptions
} from '@/helpers/search';
import type { QueryValue } from '@/types/common';

interface SavedSearch {
  id: string;
  name?: string;
  user_id: string;
  params: string;
  notifications?: {
    email?: boolean;
  };
}

export default function () {
  const { t, n, locale } = useNuxtApp().$i18n;

  const language = computed(() => {
    return locale.value.startsWith('us') ? 'en' : 'es';
  });

  const localePath = useLocalePath();

  const host = useHost();

  const { getSearchSummary } = useGetSeo();

  function tl(key: string, locale: string = 'us') {
    return t(key, null, { locale });
  }

  const areaOptions = areaOptionsRaw.map(value => ({
    value,
    text: Intl.NumberFormat(language.value).format(value) + ' sqft'
  }));

  const condoCostOptions = condoCostOptionsRaw.map(value => ({
    value,
    text: n(value, 'currency', language.value)
  }));

  const hopaOptions = hopaOptionsRaw.map(v => ({
    ...v,
    text: t(v.text)
  }));

  const buyTypeOptions = buyTypeOptionsRaw.map(v => ({
    ...v,
    text: t(v.text)
  }));

  const buyTypeOptionsAlt = buyTypeOptionsAltRaw.map(v => ({
    ...v,
    text: t(v.text, 2)
  }));

  const projectTypeOptions = projectTypeOptionsRaw.map(v => ({
    ...v,
    text: t(v.text)
  }));

  const propertyTypesOptions = propertyTypesOptionsRaw.map(v => ({
    ...v,
    text: t(v.text)
  }));

  const propertySubTypeOptions = propertySubTypeOptionsRaw.map(v => ({
    ...v,
    text: t(v.text)
  }));

  const propertySubTypesOptions = propertySubTypesOptionsRaw.map(v => ({
    ...v,
    text: t(v.text)
  }));

  const sortByOptions = sortByOptionsRaw.map(v => ({
    ...v,
    text: t(v.text)
  }));

  const sortProjectsByOptions = sortProjectsByOptionsRaw.map(v => ({
    ...v,
    text: t(v.text)
  }));

  const viewOptions = viewOptionsRaw.map(v => ({
    ...v,
    value: (
      v[('value-' + language.value) as 'es'] ||
      v.value ||
      v[language.value as 'es'] ||
      v.en ||
      ''
    ).toLowerCase(),
    text: v[language.value as 'es'] || v.en
  }));

  interface ParamItem {
    legacyParamIndex?: number;
    legacyProjectParamIndex?: number;
    keys: string[];
    range?: true | [string, string];
    getLabel?:
      | false
      | ((value: string | number | boolean, index?: number) => string);
    hideKey?: boolean;
    hideValue?: boolean;
    showKeyOnEmpty?: boolean;
    includeIn: 'properties' | 'projects';
    options?: {
      text: string;
      value: string | number;
    }[];
  }
  const paramsKeys = {
    buyType: {
      legacyParamIndex: 0,
      keys: [
        tl('paramsKeys.buyType', 'us'),
        tl('paramsKeys.buyType', 'mx'),
        tl('legacyParamsKeys.buyType', 'us'),
        tl('legacyParamsKeys.buyType', 'mx')
      ],
      getLabel: (value: string) => {
        return buyTypeOptionsAlt.find(v => v.value === value)?.text || value;
      },
      hideKey: true,
      includeIn: 'properties',
      options: buyTypeOptions
    } as ParamItem,
    county: {
      keys: [
        tl('paramsKeys.county', 'us'),
        tl('paramsKeys.county', 'mx'),
        tl('legacyParamsKeys.county', 'us'),
        tl('legacyParamsKeys.county', 'mx')
      ],
      legacyParamIndex: 1,
      legacyProjectParamIndex: 0,
      getLabel: capitalizeWords,
      showKeyOnEmpty: true
    } as ParamItem,
    city: {
      keys: [
        tl('paramsKeys.city', 'us'),
        tl('paramsKeys.city', 'mx'),
        tl('legacyParamsKeys.city', 'us'),
        tl('legacyParamsKeys.city', 'mx')
      ],
      legacyParamIndex: 2,
      legacyProjectParamIndex: 1,
      getLabel: capitalizeWords,
      showKeyOnEmpty: true
    } as ParamItem,
    zipcode: {
      keys: [
        tl('paramsKeys.zipcode', 'us'),
        tl('paramsKeys.zipcode', 'mx'),
        tl('legacyParamsKeys.zipcode', 'us'),
        tl('legacyParamsKeys.zipcode', 'mx')
      ],
      showKeyOnEmpty: true
    } as ParamItem,
    neighborhood: {
      keys: [
        'zn',
        tl('paramsKeys.neighborhood', 'us'),
        tl('paramsKeys.neighborhood', 'mx'),
        tl('legacyParamsKeys.neighborhood', 'us'),
        tl('legacyParamsKeys.neighborhood', 'mx')
      ],
      getLabel: capitalizeWords,
      showKeyOnEmpty: true
    } as ParamItem,
    location: {
      keys: [
        'se',
        tl('paramsKeys.location', 'us'),
        tl('paramsKeys.location', 'mx'),
        tl('legacyParamsKeys.location', 'us'),
        tl('legacyParamsKeys.location', 'mx')
      ],
      getLabel: capitalizeWords,
      showKeyOnEmpty: true
    } as ParamItem,
    propertyType: {
      keys: [
        tl('paramsKeys.propertyType', 'us'),
        tl('paramsKeys.propertyType', 'mx'),
        tl('legacyParamsKeys.propertyType', 'us'),
        tl('legacyParamsKeys.propertyType', 'mx')
      ],
      getLabel: (value: string) => {
        return propertyTypesOptions.find(v => v.value === value)?.text || value;
      },
      hideKey: true,
      options: propertyTypesOptions
    } as ParamItem,
    propertySubType: {
      keys: [
        'st',
        tl('paramsKeys.propertySubType', 'us'),
        tl('paramsKeys.propertySubType', 'mx'),
        tl('legacyParamsKeys.propertySubType', 'us'),
        tl('legacyParamsKeys.propertySubType', 'mx')
      ],
      getLabel: (value: string) => {
        return (
          propertySubTypesOptions.find(v => v.value === value)?.text || value
        );
      }
    } as ParamItem,
    projectType: {
      keys: [
        tl('paramsKeys.projectType', 'us'),
        tl('paramsKeys.projectType', 'mx'),
        tl('legacyParamsKeys.projectType', 'us'),
        tl('legacyParamsKeys.projectType', 'mx')
      ],
      getLabel: (value: string) => {
        return projectTypeOptions.find(v => v.value === value)?.text || value;
      },
      hideKey: true,
      options: projectTypeOptions
    } as ParamItem,
    price: {
      keys: [
        tl('paramsKeys.price', 'us'),
        tl('paramsKeys.price', 'mx'),
        tl('legacyParamsKeys.price', 'us'),
        tl('legacyParamsKeys.price', 'mx')
      ],
      range: ['priceFrom', 'priceTo'],
      getLabel: (value: number, index = 0) => {
        if (typeof value !== 'number') return '';
        return t(index === 0 ? 'common.fromN' : 'common.toN', [
          t('seo.numberArticle', [n(value, 'currency')])
        ]);
      }
    } as ParamItem,
    area: {
      keys: [
        'sqft',
        tl('paramsKeys.area', 'us'),
        tl('paramsKeys.area', 'mx'),
        tl('legacyParamsKeys.area', 'us'),
        tl('legacyParamsKeys.area', 'mx')
      ],
      range: ['areaFrom', 'areaTo'],
      getLabel: (value: number, index = 0) => {
        if (typeof value !== 'number') return '';
        return t(index === 0 ? 'common.fromN' : 'common.toN', [
          t('seo.numberArticle', [t('search.nArea', [value])])
        ]);
      }
    } as ParamItem,
    year: {
      keys: [
        'yr',
        tl('paramsKeys.year', 'us'),
        tl('paramsKeys.year', 'mx'),
        tl('legacyParamsKeys.year', 'us'),
        tl('legacyParamsKeys.year', 'mx')
      ],
      range: ['yearFrom', 'yearTo'],
      getLabel: (value: number, index = 0) => {
        if (typeof value !== 'number') return '';
        return t(index === 0 ? 'common.fromN' : 'common.toN', [
          t('search.nYear', [value])
        ]);
      }
    } as ParamItem,
    bedrooms: {
      keys: [
        'bd',
        tl('paramsKeys.bedrooms', 'us'),
        tl('paramsKeys.bedrooms', 'mx'),
        tl('legacyParamsKeys.bedrooms', 'us'),
        tl('legacyParamsKeys.bedrooms', 'mx')
      ],
      range: ['bedroomsFrom', 'bedroomsTo'],
      getLabel: (value: number, index = 0) => {
        if (typeof value !== 'number') return '';
        return t(index === 0 ? 'common.fromN' : 'common.toN', [
          t('search.nBedrooms', [value])
        ]);
      }
    } as ParamItem,
    bathsFull: {
      keys: [
        tl('paramsKeys.bathsFull', 'us'),
        tl('paramsKeys.bathsFull', 'mx'),
        tl('legacyParamsKeys.bathsFull', 'us'),
        tl('legacyParamsKeys.bathsFull', 'mx')
      ],
      range: ['bathroomsFrom', 'bathroomsTo'],
      getLabel: (value: number, index = 0) => {
        if (typeof value !== 'number') return '';
        return t(index === 0 ? 'common.fromN' : 'common.toN', [
          t('search.nBathrooms', [value])
        ]);
      }
    } as ParamItem,
    hopa: {
      keys: [
        tl('paramsKeys.hopa', 'us'),
        tl('paramsKeys.hopa', 'mx'),
        tl('legacyParamsKeys.hopa', 'us'),
        tl('legacyParamsKeys.hopa', 'mx')
      ],
      getLabel: (value: string) => {
        return hopaOptions.find(v => v.value === value)?.text || value;
      },
      hideKey: true,
      includeIn: 'properties',
      options: hopaOptions
    } as ParamItem,
    furnished: {
      keys: [
        tl('paramsKeys.furnished', 'us'),
        tl('paramsKeys.furnished', 'mx'),
        tl('legacyParamsKeys.furnished', 'us'),
        tl('legacyParamsKeys.furnished', 'mx')
      ],
      getLabel: (value: boolean) => {
        return value ? t('search.furnished') : '';
      }
    } as ParamItem,
    view: {
      keys: [
        tl('paramsKeys.view', 'us'),
        tl('paramsKeys.view', 'mx'),
        tl('legacyParamsKeys.view', 'us'),
        tl('legacyParamsKeys.view', 'mx')
      ],
      getLabel: false // TODO Implement
    } as ParamItem,
    condoCost: {
      keys: [
        tl('paramsKeys.condoCost', 'us'),
        tl('paramsKeys.condoCost', 'mx'),
        tl('legacyParamsKeys.condoCost', 'us'),
        tl('legacyParamsKeys.condoCost', 'mx')
      ],
      range: ['condoCostFrom', 'condoCostTo'],
      getLabel: false // TODO Implement
    } as ParamItem,
    dock: {
      keys: [
        tl('paramsKeys.dock', 'us'),
        tl('paramsKeys.dock', 'mx'),
        tl('legacyParamsKeys.dock', 'us'),
        tl('legacyParamsKeys.dock', 'mx')
      ],
      getLabel: false // TODO Implement
    } as ParamItem,
    shortTermLease: {
      keys: [
        tl('paramsKeys.shortTermLease', 'us'),
        tl('paramsKeys.shortTermLease', 'mx'),
        tl('legacyParamsKeys.shortTermLease', 'us'),
        tl('legacyParamsKeys.shortTermLease', 'mx')
      ],
      getLabel: (value: boolean) => {
        return value ? t('search.shortTermLease') : '';
      },
      hideValue: true
    } as ParamItem,
    excludePendingAndUnderContract: {
      keys: [
        tl('paramsKeys.excludePendingAndUnderContract', 'us'),
        tl('paramsKeys.excludePendingAndUnderContract', 'mx'),
        tl('legacyParamsKeys.excludePendingAndUnderContract', 'us'),
        tl('legacyParamsKeys.excludePendingAndUnderContract', 'mx')
      ],
      getLabel: (value: boolean) => {
        return !value ? t('search.pendingAndUnderContract') : '';
      },
      hideValue: true
    } as ParamItem,
    inListing: {
      keys: [
        tl('paramsKeys.inListing', 'us'),
        tl('paramsKeys.inListing', 'mx'),
        tl('legacyParamsKeys.inListing', 'us'),
        tl('legacyParamsKeys.inListing', 'mx')
      ],
      getLabel: (value: boolean) => {
        return value ? t('search.inListing') : '';
      },
      hideValue: true
    } as ParamItem,
    geolocation: {
      keys: [
        tl('paramsKeys.geolocation', 'us'),
        tl('paramsKeys.geolocation', 'mx'),
        tl('legacyParamsKeys.geolocation', 'us'),
        tl('legacyParamsKeys.geolocation', 'mx')
      ],
      getLabel: false // TODO Implement
    } as ParamItem,
    page: {
      keys: [
        tl('paramsKeys.page', 'us'),
        tl('paramsKeys.page', 'mx'),
        tl('legacyParamsKeys.page', 'us'),
        tl('legacyParamsKeys.page', 'mx')
      ],
      getLabel: false
    } as ParamItem,
    sortBy: {
      keys: [
        'sr',
        tl('paramsKeys.sortBy', 'us'),
        tl('paramsKeys.sortBy', 'mx'),
        tl('legacyParamsKeys.sortBy', 'us'),
        tl('legacyParamsKeys.sortBy', 'mx')
      ],
      getLabel: false,
      options: [...sortByOptions, ...sortProjectsByOptions]
    } as ParamItem
    // usedProject (Not used in search)
  } as const;

  const possibleParamsKeys = Object.values(paramsKeys)
    .map(param => param.keys)
    .flat();

  type ParamKey = keyof typeof paramsKeys;

  // Checks if the key is in the paramsKeys object.
  // Returns [key, param], [paramKey, param, key, rangeIndex] or null
  function getParamKeyEntry(
    key: string
  ): [ParamKey, ParamItem] | [ParamKey, ParamItem, string, number] {
    if (key in paramsKeys) {
      return [key as ParamKey, paramsKeys[key as ParamKey] as ParamItem];
    }

    const entryFromRange = Object.entries(paramsKeys).find(entry => {
      return (
        'range' in entry[1] &&
        Array.isArray(entry[1].range) &&
        entry[1].range.includes(key)
      );
    });

    if (entryFromRange) {
      const paramKey = entryFromRange[0] as ParamKey;
      const value = entryFromRange[1];
      return [
        paramKey,
        value,
        key,
        'range' in value &&
          Array.isArray(value.range) &&
          value.range.indexOf(key)
      ];
    }

    return null;
  }

  const searchParamsKeys = Object.entries(paramsKeys)
    .map(([key, param]) => {
      if ('range' in param && Array.isArray(param.range)) {
        return param.range;
      }
      return key;
    })
    .flat();

  const specialCharactersParams = ['geolocation'];

  const labelsParams = [
    'buyType',
    'propertyType',
    'propertySubType',
    'projectType',
    'hopa',
    'sortBy'
  ];

  function getSearchParameters(
    inputQuery: Record<string, QueryValue>,
    t: (k: string) => string
  ): string[] {
    const query: Record<string, string> = {};

    Object.entries(inputQuery).forEach(([key, value]) => {
      if ([...specialCharactersParams, ...labelsParams].includes(key)) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            query[key] = value.join(',');
          }
        } else if (value) {
          query[key] = value.toString();
        }
        return;
      }
      if (Array.isArray(value)) {
        if (value.length > 0) {
          query[key] = value.map(v => (v ? valueToParam(v) : '')).join(',');
        }
      } else if (value) {
        query[key] = valueToParam(value);
      }
    });

    const isProject = inputQuery.isProject;

    delete query.isProject;

    Object.entries(paramsKeys).forEach(([key, param]) => {
      if (isProject && param.includeIn === 'properties') {
        delete query[key];
      }
      if ('range' in param && Array.isArray(param.range)) {
        const values = param.range.map(key => query[key]);
        if (values[0] && values[1]) {
          query[key] = `${values[0]}-${values[1]}`;
        } else if (values[0]) {
          query[key] = `${values[0]}p`;
        } else if (values[1]) {
          query[key] = `0-${values[1]}`;
        }
        delete query[param.range[0]];
        delete query[param.range[1]];
      }
    });

    if (query.geolocation) {
      delete query.location;
    }

    return Object.keys(paramsKeys)
      .map(key => {
        if (
          paramsKeys[key as keyof typeof paramsKeys]?.showKeyOnEmpty &&
          query[key] === 'constempty'
        ) {
          return t(`paramsKeys.${key}`);
        }

        // console.log({ key, value: query[key], param: paramsKeys[key] });

        if (query[key]) {
          const value = query[key].includes(',')
            ? query[key].split(',')
            : query[key];

          let strValue: string = query[key];

          if (labelsParams.includes(key)) {
            if (Array.isArray(value)) {
              strValue = value
                .map((v: string) => {
                  return v ? valueToParam(v, 'force', t) : v;
                })
                .join(',');
            } else if (typeof value === 'string') {
              strValue = valueToParam(value, 'force', t);
            }
          }

          // keyless params
          if (paramsKeys[key as keyof typeof paramsKeys]?.hideKey) {
            return strValue;
          }

          // boolean params (only uses key)
          if (paramsKeys[key as keyof typeof paramsKeys]?.hideValue) {
            return t(`paramsKeys.${key}`);
          }

          return key ? strValue + '-' + t(`paramsKeys.${key}`) : strValue;
        }

        return '';
      })
      .filter(Boolean);
  }

  function getSearchString(
    key: ParamKey,
    value: string | number | boolean,
    rangeIndex = 0
  ) {
    const getLabelFunc = paramsKeys[key].getLabel;

    if (getLabelFunc === false) {
      return '';
    }

    if (typeof getLabelFunc === 'function') {
      return getLabelFunc(value, rangeIndex);
    }

    return value ? value.toString() : '';
  }

  function getBreadcrumbs(
    inputQuery: Record<string, QueryValue>,
    path: string = tl('routes.properties')
  ) {
    const query = { ...inputQuery };
    const translatedPath =
      path === tl('routes.properties')
        ? t('routes.properties')
        : t('routes.projects');

    if (query.county === 'constempty') {
      delete query.county;
    }

    if (query.city === 'constempty') {
      delete query.city;
    }

    if (query.zipcode === 'constempty') {
      delete query.zipcode;
    }

    if (query.location === 'constempty') {
      delete query.location;
    }

    const breadcrumbs: {
      name: string;
      item?: string;
      interactuable?: boolean;
    }[] = [
      {
        name: t('seo.home'),
        item: host + localePath('/')
      }
    ];

    if (path === tl('routes.properties')) {
      const defaultPath = getSearchParameters({ buyType: 'RES' }, t).join('/');
      breadcrumbs.push({
        name: t('seo.defaultMultiple'), // TODO: Change path
        item:
          host + localePath(`/${t('routes.properties')}`) + '/' + defaultPath
      });
    } else if (path === tl('routes.projects')) {
      breadcrumbs.push({
        name: t('project.newProjects'),
        item: host + localePath(`/${t('routes.projects')}`)
      });
    }

    if (
      query.location &&
      (query.county ||
        query.city ||
        query.zipcode ||
        query.neighborhood ||
        query.geolocation)
    ) {
      delete query.location;
    }

    if (!query.buyType && path === tl('routes.properties')) {
      query.buyType = 'RES';
    }

    searchParamsKeys.forEach(key => {
      const paramKeyEntry = getParamKeyEntry(key);

      if (!paramKeyEntry) return;

      const [paramKey, _param, rangeKey, rangeIndex] = paramKeyEntry;

      const queryKey = rangeKey || paramKey;

      if (!query[queryKey]) return;

      const parametersObject = {
        [queryKey]: query[queryKey]
      };

      if (queryKey === 'propertySubType') {
        parametersObject.propertyType = query.propertyType;
      }

      if (query.buyType) {
        parametersObject.buyType = query.buyType;
      }

      if (queryKey === 'neighborhood') {
        if (query.zipcode) {
          parametersObject.zipcode = query.zipcode;
        }
      }

      if (queryKey === 'zipcode' || queryKey === 'neighborhood') {
        if (query.city) {
          parametersObject.city = query.city;
        }
      }

      if (
        queryKey === 'city' ||
        queryKey === 'zipcode' ||
        queryKey === 'neighborhood'
      ) {
        if (query.county) {
          parametersObject.county = query.county;
        }
      }

      if (queryKey === 'priceTo') {
        if (!parametersObject.priceFrom) {
          parametersObject.priceFrom =
            (query.buyType === 'RNT'
              ? rentPrices[0]?.value
              : buyPrices[0]?.value) || 0;
        }
      }

      if (queryKey === 'areaTo') {
        if (!parametersObject.areaFrom) {
          parametersObject.areaFrom = areaOptions[0]?.value || 0;
        }
      }

      const searchParameters = getSearchParameters(parametersObject, t);

      const paramValue = Array.isArray(query[queryKey])
        ? (query[queryKey] as string[] | number[])[rangeIndex || 0]
        : (query[queryKey] as string | number | boolean);

      const searchString = getSearchString(paramKey, paramValue, rangeIndex);

      const itemPath = `/${translatedPath}/${searchParameters.join('/')}`;

      const breadcrumb = {
        name: searchString,
        item: query[key] ? host + localePath(itemPath) : undefined
      };

      if (!breadcrumb.name) return;

      breadcrumbs.push(breadcrumb);
    });

    if ('property' in query) {
      breadcrumbs.push({
        name: t('search.property')
      });
    }

    if ('project' in query) {
      breadcrumbs.push({
        name: (query.project || t('project.project')).toString()
      });
    }

    if ('projectAddress' in query) {
      breadcrumbs.push({
        name: query.projectAddress.toString()
      });
    }

    return breadcrumbs.filter(b => b.name);
  }

  const subTypeMap = computed(() => {
    return propertySubTypeOptions.reduce((acc, cur) => {
      acc[cur.value] = cur.text;
      return acc;
    }, {} as Record<string, string>);
  });

  async function saveSearch(
    name: string,
    params: string,
    requestParams: string,
    _check = false
  ) {
    // TODO: Check if search already exists before saving it

    if (typeof window === 'undefined' || !window.userId) {
      console.error('Error trying save search', 'User is not logged in');
      return null;
    }

    const { mutate } = useMutation(CREATE_SAVED_SEARCH, {
      variables: {
        user_id: window.userId,
        name: name || '',
        params,
        params_search: requestParams
      }
    });

    return await mutate();
  }

  interface Notifications {
    email?: boolean;
  }

  async function updateSearch(
    searchId: string,
    input: {
      name?: string;
      params?: string;
      notifications?: boolean | Notifications;
    }
  ) {
    if (typeof window === 'undefined' || !window.userId) {
      console.error('Error trying update search', 'User is not logged in');
      return null;
    }

    if (input.notifications === true) {
      input.notifications = { email: true };
    } else if (input.notifications === false) {
      input.notifications = { email: false };
    }

    const { mutate } = useMutation(UPDATE_SAVED_SEARCH_BY_ID, {
      variables: {
        id: searchId,
        input
      }
    });

    return await mutate();
  }

  async function deleteSearch(searchId: string) {
    if (typeof window === 'undefined' || !window.userId) {
      console.error('Error trying delete search', 'User is not logged in');
      return null;
    }

    const { mutate } = useMutation(DELETE_SAVED_SEARCH_BY_ID, {
      variables: {
        id: searchId
      }
    });

    return await mutate();
  }

  function resolveClient() {
    if (typeof window !== 'undefined') {
      if (window.apolloClient?.resolveClient) {
        return window.apolloClient.resolveClient();
      }

      if (window.apolloClient?.client) {
        return window.apolloClient.client;
      }

      return window.apolloClient;
    }

    return false;
  }

  async function getSearches(
    page: number,
    perPage: number,
    forceUpdate = false
  ) {
    if (typeof window === 'undefined' || !window.userId) {
      console.error('Error trying to get searches', 'User is not logged in');
      return null;
    }

    const apolloClient = resolveClient() as ApolloClient<any> | null;

    if (!apolloClient) {
      console.error('Error trying to get searches', 'Apollo client not found');
      return null;
    }

    const searchesResult = await apolloClient.query<{
      searches_saved: SavedSearch[];
      searches_saved_aggregate: {
        aggregate: {
          count: number;
        };
      };
    }>({
      query: GET_SAVED_SEARCHES_BY_USER,
      variables: {
        user_id: window.userId,
        offset: (page - 1) * perPage,
        limit: perPage
      },
      fetchPolicy: forceUpdate ? 'no-cache' : 'cache-first'
    });

    return {
      results: searchesResult.data.searches_saved,
      total: searchesResult.data.searches_saved_aggregate.aggregate.count
    };
  }

  function paramsToObject(params: string) {
    return Object.fromEntries(
      params
        .split('/')
        .map((p, index) => {
          return getParam(p, index, false);
        })
        .flat(1)
        .map(p => {
          return p ? [p.key, p.value] : null;
        })
        .filter(Boolean)
    );
  }

  function createSearchParams(
    params: Record<string, string | number | boolean | string[]>
  ) {
    return createSearchParamsRaw(params, language.value as 'en' | 'es');
  }

  interface Param {
    key: string;
    value: string | string[] | number | boolean | undefined;
  }

  function rangeParam(value: string, keys: [string, string]): Param | Param[] {
    // if param is 0-100, return { key: keys[1], value: 100 }
    // if param is 100p, return { key: keys[0], value: 100 }
    // if param is 2 and keys[0] is bathroomsFrom or bedroomsFrom, return { key: keys[0], value: 2 }
    // if param is 50-150, return [{ key: keys[0], value: 50 }, { key: keys[1], value: 150 }]

    const legacyDefaultMinKeys = ['bathroomsFrom', 'bedroomsFrom'];

    if (value.includes('-')) {
      const [from, to] = value.split('-');
      if (from !== '0') {
        return [
          { key: keys[0], value: from },
          { key: keys[1], value: to }
        ];
      } else {
        return { key: keys[1], value: to };
      }
    } else if (value.endsWith('p') || legacyDefaultMinKeys.includes(keys[0])) {
      return { key: keys[0], value: value.replace('p', '') };
    } else {
      return { key: keys[1] && keys[1] !== '_' ? keys[1] : keys[0], value };
    }
  }

  function getParam(
    param: string,
    index: number,
    isProject = false,
    throwOnError = true
  ): Param | Param[] | null {
    const LEGACY_PARAM_INDEX = isProject
      ? 'legacyProjectParamIndex'
      : 'legacyParamIndex';

    const keysArray = Object.entries(paramsKeys).map(([key, value]) => ({
      key,
      ...value
    }));

    let foundKey = '';

    let keyType = 'key' as 'key' | 'alias' | 'legacy index';

    // find by key

    let foundParam = keysArray.find(value =>
      value.keys.some(k => {
        if (param.endsWith(`-${k}`) || param.endsWith(`_${k}`)) {
          foundKey = k;
          return true;
        }
        return false;
      })
    );

    // find by alias

    if (!foundParam) {
      const foundValues = param.split(',').map(p => valuesMap[p]);
      if (foundValues.every(v => v)) {
        foundParam = keysArray.find(value => {
          if (
            'options' in value &&
            Array.isArray(value.options) &&
            foundValues.every(foundValue =>
              value.options.find(o => o.value === foundValue)
            )
          ) {
            keyType = 'alias';
            return true;
          }
          return false;
        });
      }
    }

    // find by special cases

    if (!foundParam) {
      const foundParam = keysArray.find(value =>
        value.keys.some(key => {
          if (param === key) {
            foundKey = key;
            return true;
          }
          return false;
        })
      );
      if (foundParam?.showKeyOnEmpty) {
        return {
          key: foundParam.key,
          value: 'constempty'
        };
      }
      if (foundParam?.hideValue) {
        return {
          key: foundParam.key,
          value: true
        };
      }
    }

    if (!foundParam && !throwOnError) {
      // return early before using legacy index
      return null;
    }

    if (!foundParam) {
      foundParam = keysArray.find(value => {
        if (
          LEGACY_PARAM_INDEX in value &&
          value[LEGACY_PARAM_INDEX] === index
        ) {
          keyType = 'legacy index';
          return true;
        }
        return false;
      });
    }

    if (!foundParam) {
      if (throwOnError) {
        showError({
          statusCode: 404,
          message: `Param '${param}' not found.`,
          fatal: true
        });
        throw new Error(t(`Param '${param}' not found.`));
      }
      return null;
    }

    let value = '';

    if (keyType === 'key') {
      value = param.includes(`-${foundKey}`)
        ? param.replace(`-${foundKey}`, '')
        : param.replace(`_${foundKey}`, '');
    } else if (keyType === 'alias') {
      value = param;
    } else if (keyType === 'legacy index') {
      value = param;
    }

    if (
      'range' in foundParam &&
      foundParam.range &&
      typeof foundParam.range === 'boolean'
    ) {
      return rangeParam(value, [foundParam.key, null]);
    } else if ('range' in foundParam && foundParam.range) {
      return rangeParam(value, foundParam.range as [string, string]);
    } else if (
      [...specialCharactersParams, ...labelsParams].includes(foundParam.key)
    ) {
      const values = value.split(',');
      return {
        key: foundParam.key,
        value: values.length > 1 ? values : values[0]
      };
    } else {
      const values = paramToValue(value).split(',');
      return {
        key: foundParam.key,
        value: values.length > 1 ? values : values[0]
      };
    }
  }

  return {
    paramsKeys,
    possibleParamsKeys,
    specialCharactersParams,
    rentPrices,
    buyPrices,
    sortByOptions,
    sortProjectsByOptions,
    areaOptions,
    buyTypeOptions,
    buyTypeOptionsAlt,
    condoCostOptions,
    hopaOptions,
    propertyTypesOptions,
    propertySubTypesOptions,
    projectTypeOptions,
    yearOptions,
    viewOptions,
    valueToParam,
    paramToValue,
    saveSearch,
    updateSearch,
    getSearches,
    deleteSearch,
    getSearchParameters,
    getBreadcrumbs,
    getSearchSummary,
    paramsToObject,
    createSearchParams,
    getParam
  };
}
